<template>
  <div class="container-fluid my-2 text-center">
    <div
      v-if="
        getUserRequests.requests.length == 0 &&
          !$apollo.queries.getUserRequests.loading &&
          !RequestFilter.filter
      "
      class="text-center my-4"
    >
      <h1>لم ترفع تسعيرات حتى الآن</h1>

      <button class="btn btn-lg mt-5 bwj-btn" @click="showModal = 1">
        أضف تسعيرة جديدة
      </button>
    </div>

    <cards
      v-if="RequestFilter.filter || getUserRequests.requests.length > 0"
      :rows="getUserRequests.requests"
      :columns="columns"
      :paginationInfo="{
        index: RequestFilter.skip,
        limit: RequestFilter.limit,
        remainingRows: getUserRequests.remainingRequests,
        rowsLength:
          getUserRequests.requests.length +
          getUserRequests.remainingRequests +
          RequestFilter.skip,
      }"
      :cardFillters="fillters"
      :cardSlots="cardSlots"
      @changePage="changePage"
      @filterItems="filterItems"
      @clearFilter="clearFilter"
    >
    <template #cardNumber="cardNumberProps">
      {{cardNumberProps.index}}
     </template>

    <template #counter="counterProps">
      <div class="row justify-content-around">
        <p class="col-5 m-auto">ينتهي خلال</p>
        <p class="col-5 m-auto text-danger" style="direction: ltr">
          <vue-countdown
            :time="RequestDate(counterProps.row.expiresAt)"
            :interval="100"
            v-slot="{ days, hours, minutes, seconds }"
          >
            {{ days }} : {{ hours }} : {{ minutes }} : {{ seconds }}
          </vue-countdown>
        </p>
      </div>
    </template>
  
      <template #actions="actionsProps">
        <div class="text-center my-2">
          <button
            class="btn bwj-btn btn-sm"
            @click="offers($event, actionsProps.row)"
          >
            استعرض العروض ({{ actionsProps.row.offers.length }})
          </button>
          <button
            class="btn btn-danger btn-sm mr-1"
            @click="removeRequest($event, actionsProps.row)"
          >
            حذف
          </button>
        </div>
      </template>
    </cards>
  </div>
</template>
<script>
import { GET_USER_REQUSTS } from "../graphql/queries";
import { REMOVE_REQUEST } from "../graphql/mutations";
import appAddress from "../components/location.vue";
import partRequest from "../components/PartOrder/PartOrder.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import cards from "../components/cards/cards.vue";
import { getModels, getFillterdModels } from "../_helpers/getModels";
import { getCatrgories, getFillterdCatrgories } from "../_helpers/getCategory";

export default {
  created() {
    if (JSON.parse(localStorage.getItem("fillters"))) {
      this.fillters = JSON.parse(localStorage.getItem("fillters"));
      this.filterItems();
    }
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      getUserRequests: {
        remainingRequests:0,
        requests: []
      },
      columns: [
        {
          key: ".name",
          label: "القطعة",
        },
        {
          key: ".model",
          label: "السيارة",
        },
        {
          key: ".manufacturer",
          label: "الشركة",
        },
        {
          key: ".year",
          label: "سنة الصنع",
        },
      ],
      skipQuery: true,
      cardSlots: {
        headerSlots: ["cardNumber"],
      topSlots:  null,
      bottomSlots:  [ "counter","actions"]
      },

      RequestFilter: {
        skip: 0,
        limit: this.$store.state.glopal.window.width < 992 ? 10 : 12,
      },
      fillters: [
        { value: [], options: getModels(), label: " المصنّع/الطراز" },
        { value: [], options: getCatrgories(), label: " الأجزاء" },
        { value: [null, null], options: [], label: "السنة" },
      ],
    };
  },

  apollo: {
    getUserRequests: {
      query: GET_USER_REQUSTS,
      variables() {
        return {
          RequestsFilter: this.RequestFilter,
        };
      },
      result(result) {
        this.$store.commit("glopal/isLoading", false);
      },
    },
  },
  components: {
    cards,
    appAddress,
    partRequest,
    VueCountdown,
  },
  methods: {
    offers(e, payload) {
      console.log(payload._id);
      this.$router.push("/Offers/" + payload._id);
    },
    removeRequest(e, payload) {
      if (confirm("هل تريد حذف هذه التسعيرة؟")) {
        this.$apollo
          .mutate({
            // Query
            mutation: REMOVE_REQUEST,
            // Parameters
            variables: {
              RequestId: payload._id,
            },
            update: (store, { data: { removeRequest } }) => {
              console.log(store);
              console.log(removeRequest);
              console.log(payload);
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: GET_USER_REQUSTS,
                variables: {
                  RequestsFilter: this.RequestFilter,
                },
              });
              console.log(data);
              data.getUserRequests.requests.splice(payload.originalIndex, 1);
              console.log(data.getUserRequests);
              store.writeQuery({
                query: GET_USER_REQUSTS,
                data,
              });
            },
          })
          .then((data) => {
            // Result
            this.$store.commit("glopal/isLoading", false);
            $("html, body").animate({ scrollTop: 0 }, "slow");

            this.$store.dispatch("alert/success", "تم حذف التسعيرة");
            console.log(data);
          })
          .catch((error) => {
            // Error
            this.$store.commit("glopal/isLoading", false);
            console.error(error);
          });
      }
    },
    RequestDate(date) {
      const now = new Date();
      return parseInt(date) - now;
    },

    changePage(index) {
      this.currentPage = index;
      this.RequestFilter.skip = (index - 1) * this.RequestFilter.limit;
      this.RequestRefetch();
    },
    filterItems() {
      localStorage.setItem("fillters", JSON.stringify(this.fillters));

      this.RequestFilter.filter = getFillterdModels(this.fillters[0].value);
      this.RequestFilter.filter.category = getFillterdCatrgories(
        this.fillters[1].value
      );
      this.RequestFilter.filter.minYear = parseInt(this.fillters[2].value[1]);
      this.RequestFilter.filter.maxYear = parseInt(this.fillters[2].value[0]);
      if (
        !this.RequestFilter.filter.model.length > 0 &&
        !this.RequestFilter.filter.category.length > 0 &&
        !this.RequestFilter.filter.minYear &&
        !this.RequestFilter.filter.maxYear
      ) {
        delete this.RequestFilter.filter;
        this.currentPage = 1;
      } else {
        if (!this.RequestFilter.filter.model.length > 0) {
          delete this.RequestFilter.filter.model;
          // delete this.RequestFilter.filter.manufacturer;
        }
        if (!this.RequestFilter.filter.category.length > 0) {
          delete this.RequestFilter.filter.category;
        }
        if (!this.RequestFilter.filter.minYear) {
          delete this.RequestFilter.filter.minYear;
        }
        if (!this.RequestFilter.filter.maxYear) {
          delete this.RequestFilter.filter.maxYear;
        }
      }
      this.RequestFilter.skip = null;
      this.RequestRefetch();
      return;
    },
    RequestRefetch() {
      this.$apollo.queries.getUserRequests.refetch().then((value) => {
        this.$store.commit("glopal/isLoading", false);
      });
    },
    RequestDate(date) {
      const now = new Date();
      return parseInt(date) - now;
    },
    clearFilter() {
      for (let index = 0; index < this.fillters.length; index++) {
        if (this.fillters[index].label == "السنة") {
          this.fillters[index].value = [null, null];
        } else {
          this.fillters[index].value = [];
        }
      }
      this.filterItems();
      return;
    },
  },
  computed: {
    time() {
      const now = new Date();
      const newYear = new Date(now.getFullYear() + 1, 0, 1);
      console.log(newYear);
      return newYear - now;
    },
    showModal: {
      get() {
        console.log(this.$store);
        return this.$store.state.glopal.ModalState;
      },
      set(newState) {
        this.$store.state.glopal.ModalState = newState;
        return;
      },
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 576px) {
  .vgt-compact td span button {
    position: static;
  }
  .vgt-compact td span p {
    display: inline-block;
  }
}
</style>
